// gatsby-browser.js

export const onClientEntry = () => {
    // 必要なポリフィルがない場合にメッセージを表示（デバッグ用）
    if (!('fetch' in window)) {
        console.log('Fetch API is missing. This browser is not supported.');
    }

    // 他の特定のポリフィルも条件付きで無効化可能
};
